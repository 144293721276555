import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { SourceKindList, SourceKindLabels, SourceKind } from "../../choices";

interface SourceKindProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  handleSourceKindChange: (kind: number) => void;
  setSelectedSourceKinds: (kinds: number[]) => void;
  selectedSourceKinds: number[];
}

const SourceKindButton: React.FC<SourceKindProps> = ({
  searchParams,
  setSearchParams,
  handleSourceKindChange,
  setSelectedSourceKinds,
  selectedSourceKinds,
}) => {
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    params.delete("sourceKind");
    selectedSourceKinds.forEach((kind) => {
      params.append("sourceKind", kind.toString());
    });
    setSearchParams(params);
  }, [selectedSourceKinds, searchParams, setSearchParams]);

  const allowedSourceKinds = [
    SourceKind.DATA_GOV.value, 
    SourceKind.OPEN_CANADA_CA.value, 
    SourceKind.EUROPA_EU.value,
  ];
  const filteredSourceKinds = SourceKindList.filter((item) =>
    allowedSourceKinds.includes(item.value)
  );

  return (
    <main>
      <div className="d-flex align-items-center">
        <DropdownButton title="Source" variant="success">
          {filteredSourceKinds.map((choice: any) => {
            return (
              <Dropdown.Item
                as="button"
                key={choice.value}
                className={
                  selectedSourceKinds.includes(choice.value)
                    ? "bg-success text-white"
                    : ""
                }
                onClick={() => handleSourceKindChange(choice.value)}
              >
                {choice.label}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </div>
      <div className="d-flex p-2 align-items-center flex-wrap">
        {selectedSourceKinds.map((sourceKind) => (
          <button
            className="btn align-items-center p-2 rounded-pill d-flex btn-sm bg-secondary-subtle text-black ms-3 m-1"
            key={sourceKind}
            onClick={() => handleSourceKindChange(sourceKind)}
          >
            <i className="bi bi-x text-black"></i>
            <label className="mx-2 text-black fw-medium">
              {SourceKindList.find((entry) => entry.value == sourceKind)?.label ?? 'Unknown'}
            </label>
          </button>
        ))}
      </div>
    </main>
  );
};

export default SourceKindButton;
